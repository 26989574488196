<template>
  <v-footer class="pa-3 blue-grey darken-3 grey--text text--lighten-1">
    <div class="slight">v{{ ver }}</div>
    <v-spacer></v-spacer>
    <div>&copy; {{ new Date().getFullYear() }} <a href="http://riichi.id">riichi.id</a></div>
  </v-footer>
</template>

<script>
export default {
  name: 'AppFooter',
  data() {
    return {
      ver: process.env.VUE_APP_VERSION
    }
  }
};
</script>

<style scoped>
a:link, a:visited, a:hover, a:active {
  text-decoration: none;
}
.slight {
  opacity: 0.15;
}
</style>
