<template>
  <div>
    <AppHome></AppHome>
  </div>
</template>

<script>
// @ is an alias to /src
import AppHome from '@/components/HomeComponent.vue';

export default {
  name: 'HomeView',
  metaInfo() {
    return {
      title: this.$t('title'),
    };
  },
  components: {
    AppHome,
  },
};
</script>
