import '@babel/polyfill';
import Vue from 'vue';
import VueGtag from 'vue-gtag';
import VueLazyload from 'vue-lazyload';
import VueYoutube from 'vue-youtube';
import VueProgress from 'vue-progress-path';
import FlagIcon from 'vue-flag-icon';
import './plugins/vuetify';
import App from './App.vue';
import router from './router';
import store from './store';
import i18n from './i18n';

import 'vue-progress-path/dist/vue-progress-path.css';

Vue.use(VueYoutube);
Vue.use(VueLazyload);
Vue.use(FlagIcon);
Vue.use(VueProgress);

Vue.config.productionTip = false;

Vue.use(VueGtag, {
  config: { id: 'G-QDSDQCZS82' },
}, router);

Vue.filter('locale', (value) => i18n.t(value));

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app');
