<template>
  <span>
    <v-toolbar app color="blue-grey darken-4" dark>
      <v-spacer class="hidden-md-and-up"></v-spacer>
      <router-link to="/">
        <v-toolbar-title class="hidden-sm-and-down">
          {{ $t('title') }} | riichi.id
        </v-toolbar-title>
        <v-toolbar-title class="hidden-md-and-up">
          riichi.id
        </v-toolbar-title>
      </router-link>
      <v-spacer></v-spacer>
      <v-lang class="hidden-sm-and-down" sm1 />
      <v-btn flat class="hidden-sm-and-down" to="/community">
        {{ $t('community') }}
      </v-btn>
      <v-btn flat class="hidden-sm-and-down" to="/etiquette">
        {{ $t('etiquette') }}
      </v-btn>
      <v-btn flat class="hidden-sm-and-down" to="/learn">
        {{ $t('learn') }}
      </v-btn>
      <v-btn flat class="hidden-sm-and-down" to="/ruleset">
        {{ $t('ruleset') }}
      </v-btn>
      <v-toolbar-side-icon class="hidden-md-and-up" @click="drawer = !drawer">
        <v-icon v-if="drawer">mdi-close</v-icon>
      </v-toolbar-side-icon>
    </v-toolbar>
    <v-navigation-drawer
      app
      clipped
      temporary
      right
      v-model="drawer"
      class="blue-grey darken-3 mt-5"
      :class="{ drawer: !isMobile(), drawerMobile: isMobile() }"
      dark
      disable-resize-watcher=""
      mobile-break-point="0"
      width="240px"
    >
      <v-list>
        <template v-for="(item, index) in items">
          <v-list-tile :key="index" :to="item.href">
            <v-list-tile-content class="tile" @click="drawer = !drawer">
              {{ $t(item.title) }}
            </v-list-tile-content>
          </v-list-tile>
        </template>
        <v-lang class="hidden-md-and-up" style="margin-left: 12px;" xs12 />
      </v-list>
    </v-navigation-drawer>
  </span>
</template>

<script>
import mixins from './../utils/helper';
import Lang from '@/components/LanguageSwitcher';
export default {
  name: 'AppNavigation',
  mixins: [mixins],
  components: {
    'v-lang': Lang
  },
  data() {
    return {
      drawer: false,
      items: [
        { title: 'community', href: '/community' },
        { title: 'etiquette', href: '/etiquette' },
        { title: 'learn', href: '/learn' },
        { title: 'ruleset', href: '/ruleset' }
      ]
    };
  }
};
</script>

<style scoped>
a {
  color: white;
  text-decoration: none;
}
.tile {
  color: white;
  text-transform: uppercase;
}
.drawer {
  top: 0;
}
@media only screen and (orientation: portrait) and (max-width: 959px) {
  .drawerMobile {
    top: 8px;
  }
}
</style>
