import Vue from 'vue';
import Router from 'vue-router';
import VueMeta from 'vue-meta';
import Home from './views/Home.vue';

Vue.use(Router);
Vue.use(VueMeta, {
  refreshOnceOnNavigation: true,
});

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior(to, from, savedPosition) {
    return savedPosition ? savedPosition : { x: 0, y: 0 };
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home
    },
    {
      path: '/community',
      name: 'community',
      component: () => import('./views/Community.vue')
    },
    {
      path: '/etiquette',
      name: 'etiquette',
      component: () => import('./views/Etiquette.vue')
    },
    {
      path: '/learn',
      name: 'learn',
      component: () => import('./views/Learn.vue')
    },
    {
      path: '/ruleset',
      name: 'ruleset',
      component: () => import('./views/Ruleset.vue')
    },
    {
      path: '/seat',
      name: 'seat-randomizer',
      component: () => import('./views/tools/Randomizer.vue')
    },
    {
      path: '/score',
      name: 'score-calculator',
      component: () => import('./views/tools/ScoreCalculator.vue')
    },
    {
      path: '/member/:memberId?',
      name: 'members',
      props: true,
      component: () => import('./views/Members.vue')
    }
  ]
});
