<template>
  <v-container
    fluid
    fill-height
    class="home"
    v-lazy:background-image="isMobile() ? backgroundMobile : backgroundDesktop"
    style="height:85vh;"
    pa-0
  >
    <v-layout align-center justify-center column class="content-layout">
      <div id="titlebar" class="display-4 content text-xs-center">
        <span>riichi.id</span>
      </div>
      <div class="icons">
        <v-btn flat icon class="socialmedia facebook" target="_blank" href="https://www.facebook.com/riichi.indonesia">
          <v-icon large>fa-brands fa-square-facebook</v-icon>
        </v-btn>
        <v-btn flat icon class="socialmedia twitter" target="_blank" href="https://www.twitter.com/RiichiIndonesia">
          <v-icon large>fa-brands fa-twitter</v-icon>
        </v-btn>
        <v-btn flat icon class="socialmedia instagram" target="_blank" href="https://www.instagram.com/riichi.indonesia">
          <v-icon large>fa-brands fa-instagram</v-icon>
        </v-btn>
        <v-btn flat icon class="socialmedia youtube" target="_blank" href="https://www.youtube.com/@RiichiIndonesia">
          <v-icon large>fa-brands fa-square-youtube</v-icon>
        </v-btn>
        <v-btn flat icon class="socialmedia discord" target="_blank" href="https://discord.gg/ECwEgC4">
          <v-icon size="32">fa-brands fa-discord</v-icon>
        </v-btn>
      </div>
    </v-layout>
    <h3 class="disclaimer">{{ $t('disclaimer') }}</h3>
  </v-container>
</template>

<script>
import mixins from './../utils/helper';
export default {
  name: 'AppHome',
  mixins: [mixins],
  data() {
    return {
      backgroundDesktop: 'https://res.cloudinary.com/riichi-id/image/upload/v1637927701/riichi.id/web/blurbackground3_kwtpyv.jpg',
      backgroundMobile: 'https://res.cloudinary.com/riichi-id/image/upload/v1637927713/riichi.id/web/blurbackground4_hngnem.jpg'
    };
  }
};
</script>

<style scoped>
.home {
  background-color: lightgrey;
  background-size: cover;
  -webkit-background-size: cover;
}
.home[lazy='loaded'] {
  background-color: lightgrey;
  background-position: center;
  /*max-width: 800px;*/
  transition: all 0.4s ease-out;
}
.content-layout {
  height: 100%;
  background: rgba(211, 211, 211, 0.45);
}
.content {
  opacity: 1 !important;
}
.socialmedia {
  margin-left: 5px;
  margin-right: 5px;
  transition: 0s ease-in-out;
  color: #313239;
}
.facebook:hover {
  color: #0866ff;
}
.twitter:hover {
  color: #1da1f2;
}
.instagram:hover {
  color: #e4405f;
}
.youtube:hover {
  color: #ff0000;
}
.discord:hover {
  color: #5865f2;
}
.icons {
  position: absolute;
  bottom: 8em;
}
.disclaimer {
  text-align: center;
  color: #313239;
  padding-left: 2em;
  padding-top: 1em;
  padding-right: 2em;
  padding-bottom: 1em;
  position: absolute;
  bottom: 0;
  width: 100vw;
  background: #68989c;
}
#titlebar {
  color: #181f16;
  font: caption;
  text-shadow: 0 0 3px #68989c, 0 0 3px #68989c, 0 0 3px #68989c, 0 0 3px #68989c;
}
</style>
