<template>
  <v-app>
    <AppNavigation></AppNavigation>
    <v-content transition="slide-x-transition">
      <router-view></router-view>
    </v-content>
    <AppFooter></AppFooter>
  </v-app>
</template>

<script>
import AppNavigation from '@/components/NavigationComponent';
import AppFooter from '@/components/FooterComponent';
export default {
  name: 'App',
  metaInfo: {
    titleTemplate: '%s | Riichi Indonesia',
  },
  components: {
    AppNavigation,
    AppFooter
  }
};
</script>

<style></style>
