<template>
  <v-flex style="max-width: 104px; margin-top: 8px; margin-right: 14px;">
    <v-select
      v-model="selected"
      item-value="code"
      :items="lang"
      v-on:change="changeLocale"
      solo
      flat
    >
      <template v-slot:selection="data">
        <v-card flat tile class="align-middle">
          <flag :iso="data.item.flag" v-bind:squared=false style="margin-right: 10px;" />
          <b>{{ data.item.code.toUpperCase() }}</b>
        </v-card>
      </template>
      <template v-slot:item="data">
        <v-card flat tile style="align-items: center">
          <flag :iso="data.item.flag" v-bind:squared=false style="margin-right: 10px;" />
          <b>{{ data.item.code.toUpperCase() }}</b>
        </v-card>
      </template>
    </v-select>
  </v-flex>
</template>

<script>
import i18n from './../i18n';
export default {
  name: 'LanguageSwitcher',
  methods: {
    changeLocale(locale) {
      this.selected = locale;
      this.$i18n.locale = locale;
    },
  },
  data: () => ({
    selected: i18n.t('locale'),
    lang: [
      {
        code: 'id',
        flag: 'id',
      },
      {
        code: 'en',
        flag: 'gb',
      },
    ],
  }),
};
</script>
