import Vue from 'vue';
import VueI18n from 'vue-i18n';
import { default as id } from './id';
import { default as en } from './en';

Vue.use(VueI18n);

const messages = {
  id: { ...id },
  en: { ...en },
};

function getInitialLocale() {
  const searchParams = new URLSearchParams(window.location.search);
  const hasHlParam = searchParams.has('hl');
  return hasHlParam ? searchParams.get('hl') : 'en';
}

export default new VueI18n({
  locale: getInitialLocale(),
  silentFallbackWarn: true,
  messages,
});
